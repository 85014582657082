import { type ApplyMergeObjects, type AsMutableArray, type MutableOrImmutableArray } from "@mcwd/typescript-type-guards";
import type { ISiteLocale, ISiteLocaleWithExtendedCountryLocales } from "./ISiteLocale.js";
import { LocaleSettingsArray, SiteLocaleWithExtendedCountryLocaleArray } from "../locale-settings.js";
import type { SiteCountryCode } from "../Locales.js";
import type { ICountryLocale } from "./ICountryLocale.js";

type BuildExtendedLocaleMappedToSiteLocale<
  TSiteCountryCode extends SiteCountryCode,
  ExtendedCountryLocales extends ICountryLocale[],
  Result extends any[] = []
> = ExtendedCountryLocales extends [infer FirstItem extends ICountryLocale, ... infer Remaining extends ICountryLocale[]]
  ? BuildExtendedLocaleMappedToSiteLocale<TSiteCountryCode, Remaining, [...Result, { [K in FirstItem["CountryLocale"]]: TSiteCountryCode}]>
  : Result;



type BuildSiteSettingsExtendedLocaleObjSplit<
  ARRAY extends MutableOrImmutableArray<ISiteLocale>,
  Result extends any[] = []
> =
  AsMutableArray<ARRAY> extends [
    infer FirstItem extends ISiteLocale,
    ... infer Remaining extends MutableOrImmutableArray<ISiteLocale>
  ]
  ? (
    [FirstItem] extends [{
      SiteCountryCode: infer TSiteCountryCode extends SiteCountryCode,
      ExtendedCountryLocales: infer ExtendedCountryLocales extends MutableOrImmutableArray<ICountryLocale>
    }]
    ? (
      BuildSiteSettingsExtendedLocaleObjSplit<
        Remaining,
        [
          ...Result,
          ...BuildExtendedLocaleMappedToSiteLocale<TSiteCountryCode, AsMutableArray<ExtendedCountryLocales>>
        ]
      >
    )
    : BuildSiteSettingsExtendedLocaleObjSplit<Remaining, [...Result]>
  ) : Result;

export type BuildSiteSettingsExtendedLocaleObj<
  ARRAY extends MutableOrImmutableArray<ISiteLocale>,
> = ApplyMergeObjects<BuildSiteSettingsExtendedLocaleObjSplit<ARRAY, []>>;

export function buildSiteLocaleExtendedLocaleSettingsObj(): BuildSiteSettingsExtendedLocaleObj<LocaleSettingsArray> {
  type ThisReturnType = BuildSiteSettingsExtendedLocaleObj<LocaleSettingsArray>;
  const localeLowerCaseMapFn = <TSiteLocale extends ISiteLocaleWithExtendedCountryLocales>(siteLocale: TSiteLocale) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return siteLocale.ExtendedCountryLocales.map(c => c.CountryLocale.toLowerCase());
  };
  const siteLocaleSettingsObj = (SiteLocaleWithExtendedCountryLocaleArray as ISiteLocaleWithExtendedCountryLocales[]).reduce(
    (result: Record<string, SiteCountryCode>, localeSettings: ISiteLocaleWithExtendedCountryLocales) => {
      const extendedLocalesObj = localeLowerCaseMapFn(localeSettings).reduce(
        (innerResult: Record<string, SiteCountryCode>, extLocaleKey: string) => {
          return Object.assign(innerResult, {
            [extLocaleKey]: localeSettings.SiteCountryCode
          });
        }, {} as Record<string, SiteCountryCode>
      );
      return { ...result, ...extendedLocalesObj };
    },
    {} as Record<string, SiteCountryCode>
  );
  Object.freeze(siteLocaleSettingsObj);

  return siteLocaleSettingsObj as ThisReturnType;
}
